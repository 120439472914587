import React from 'react'
import { Link } from 'gatsby'
import ColWrap from '../Helpers/ColWrap'

const HomeMeerInfo = ({
  homeMI1title,
  homeMI1text,
  homeMI1btnText,
  homeMI2title,
  homeMI2text,
  homeMI2btnText,
  homeMI3title,
  homeMI3text,
  homeMI3btnText,
}) => {
  return (
    <section className="home-meer-info section medium">
      <div className="container">
        <ColWrap>
          <div className="column is-6 is-flex flex-column">
            <h4>{homeMI1title}</h4>
            <div dangerouslySetInnerHTML={{ __html: homeMI1text }} />
            <div>
              <Link to="/contact" className="cta-link">
                {homeMI1btnText}
              </Link>
            </div>
          </div>
          <div className="column is-6 is-flex flex-column">
            <h4>{homeMI3title}</h4>
            <div dangerouslySetInnerHTML={{ __html: homeMI3text }} />
            <div>
              <Link to="/energetisch-renoveren" className="cta-link">
                {homeMI3btnText}
              </Link>
            </div>
          </div>
        </ColWrap>
      </div>
    </section>
  )
}

export default HomeMeerInfo
