import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import ColWrap from '../Helpers/ColWrap'
import homeScanImg from '../../img/icon-scan.png'
import homePlanImg from '../../img/icon-plan.png'
import homeRenoImg from '../../img/icon-renoveer.png'
import homeGenietImg from '../../img/icon-geniet.png'
import homeStapPijl from '../../img/icon-pijl.png'

const HomeStappenPlan = ({
  homeIntro,
  homeIntroTitle,
  homeStap1Titel,
  homeStap1Tekst,
  homeStap2Titel,
  homeStap2Tekst,
  homeStap3Titel,
  homeStap3Tekst,
  homeStap4Titel,
  homeStap4Tekst,
  homeOutro,
  homeOutroBtnTekst,
}) => {
  return (
    <>
      <section className="home-intro section color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 has-text-centered">
              <h1 className="title">{homeIntroTitle}</h1>
              <p dangerouslySetInnerHTML={{ __html: homeIntro }} />
            </div>
          </div>
        </div>
      </section>
      <section className="home-stappenplan section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <Link to="/energetisch-renoveren">
                <div className="columns is-gapless has-text-centered">
                  <div className="column stap is-2">
                    <div className="stap-img">
                      <img src={homeScanImg} alt="stapIcon" />
                    </div>
                    <h3>{homeStap1Titel}</h3>
                    <div>{parse(homeStap1Tekst)}</div>
                  </div>
                  <div className="column pijl">
                    <div className="stap-pijl">
                      <img src={homeStapPijl} alt="stapIcon" />
                    </div>
                  </div>
                  <div className="column stap is-2">
                    <div className="stap-img">
                      <img src={homePlanImg} alt="stapIcon" />
                    </div>
                    <h3>{homeStap2Titel}</h3>
                    <div>{parse(homeStap2Tekst)}</div>
                  </div>
                  <div className="column pijl">
                    <div className="stap-pijl">
                      <img src={homeStapPijl} alt="stapIcon" />
                    </div>
                  </div>
                  <div className="column stap is-2">
                    <div className="stap-img">
                      <img src={homeRenoImg} alt="stapIcon" />
                    </div>
                    <h3>{homeStap3Titel}</h3>
                    <div>{parse(homeStap3Tekst)}</div>
                  </div>
                  <div className="column pijl">
                    <div className="stap-pijl">
                      <img src={homeStapPijl} alt="stapIcon" />
                    </div>
                  </div>
                  <div className="column stap is-2">
                    <div className="stap-img">
                      <img src={homeGenietImg} alt="stapIcon" />
                    </div>
                    <h3>{homeStap4Titel}</h3>
                    <div>{parse(homeStap4Tekst)}</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="home-outro section color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6 has-text-centered">
              <p dangerouslySetInnerHTML={{ __html: homeOutro }} />
            </div>
          </div>
          <ColWrap>
            <div className="column is-4 ">
              <Link
                dangerouslySetInnerHTML={{ __html: homeOutroBtnTekst }}
                to="/energetisch-renoveren"
                className="cta-link full-width"
              />
            </div>
          </ColWrap>
        </div>
      </section>
    </>
  )
}

export default HomeStappenPlan
