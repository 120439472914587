import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ColWrap from '../Helpers/ColWrap'

const HomeWie = ({
  homeWieImg,
  homeWieCredits,
  homeWieCreditsLink,
  homeWieIntro,
  homeWieSub,
  homeWieBtnTekst,
}) => {
  return (
    <section className="home-wie section medium color-bg">
      <div className="container">
        <ColWrap>
          <div className="column is-4">
            <div className="portrait">
              {homeWieImg.localFile ? (
                <Img
                  fluid={homeWieImg.localFile.childImageSharp.fluid}
                  alt={homeWieImg.alt_text}
                  title={homeWieImg.title}
                />
              ) : null}
              {homeWieCredits ? (
                <div className="foto-credits">
                  <a href={homeWieCreditsLink} rel="noreferrer" target="_blank">
                    {homeWieCredits}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="column content is-8">
            <div
              className="quote"
              dangerouslySetInnerHTML={{ __html: homeWieIntro }}
            />
            <div
              className="focus"
              dangerouslySetInnerHTML={{ __html: homeWieSub }}
            />
            <Link to="/wie" className="cta-link">
              {homeWieBtnTekst}
            </Link>
          </div>
        </ColWrap>
      </div>
    </section>
  )
}

export default HomeWie
