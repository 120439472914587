import React from 'react'
import { Link } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import ProjectItem from '../ProjectItem'
import ColWrap from '../Helpers/ColWrap'

export default class HomeProjectList extends React.Component {
  render() {
    const { posts } = this.props
    return (
      <section className="home-projecten section medium has-text-centered">
        <div className="container">
          <ColWrap>
            {posts.map(({ node: post }) => (
              <div className="column is-4" key={uuidv4()}>
                <ProjectItem post={post} />
              </div>
            ))}
          </ColWrap>

          <ColWrap>
            <div className="column is-4">
              <Link to="/projecten" className="cta-link full-width">
                ontdek meer projecten
              </Link>
            </div>
          </ColWrap>
        </div>
      </section>
    )
  }
}

HomeProjectList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
}
